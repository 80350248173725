import { useState} from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/InputField/InputField";

type AppProps = {
  createModal: boolean,
  closeModal: Function,
  handleSubmit: Function
}; 

const AddWatchList = ({createModal , closeModal , handleSubmit} : AppProps) => {
  // const [able, setAble] = useState(false);
  ////validation////////
  const validate = Yup.object({
    name: Yup.string().required("WatchList Name is required"),
  });
  ////end-validation////////

  const close = () => {
    closeModal()
  }
  return (
    <>
      <Modal
        className="table-modal add-watchlist-modal"
        show={createModal ? true : false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="text-left d-inline-block w-100">
            Add Watch List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Formik
              initialValues={{
                name: "",
                // description:''
              }}
              validationSchema={validate}
              onSubmit={(values) => {
                // setAble(true);
                handleSubmit(values.name)
              }}
            >
              {(formik) => (
                <>
                  <Form>
                    <InputField
                      type="text"
                      label="WatchList Name"
                      name="name"
                      placeholder="Enter WatchList Name"
                    />
                    <div className="form-group d-inline-flex justify-content-end w-100 position-relative">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={able}
                        name="submit"
                        style={{ background: "#3969d5" }}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-cancel ml-2"
                        name="cancel"
                        onClick={close}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddWatchList;
