import React, { useState, useEffect } from 'react';
import { Container , Row , Col } from 'react-bootstrap';
import logo from '../../assets/media/images/askanalyst.svg';
import UserImg from '../../assets/media/images/profile_user.jpg';
// import { Cookies, useCookies } from 'react-cookie';
// import { useHistory } from 'react-router-dom';
import './Navigation.css';
// import { Redirect , useHistory } from 'react-router-dom';


const Navigation = () => {
    // const history = useHistory();
    
    // const [userAction , setUserAction] = useState(false);
    // const [cookies, setCookies, removeCookies] = useCookies(['accessToken', 'user']);
    
    // const [pageTitle , setPageTitle] = useState(titleText);
    
    // const [userInfo, setUserInfo] = useState(cookies.userInformation);

    // console.log("User Information in header", cookies);
    // const userInfo = JSON.parse(localStorage.getItem("userActive"));
    // console.log(userInfo , "userInfo");

    // const userInitial = userInfo.name.split('')[0];
    
    // console.log(userInfo.name.split('')[0] , "first letter");

    // const handleUserAction = () => {
    //     setUserAction(!userAction);
    // }

    // const handleLogout = () =>{
    //     removeCookies("accessToken");
    //     removeCookies("user");
    //     removeCookies("userInformation");
    //     return(<Redirect to="/" />);
    // }

    // const handleReset = () => {
    //     var accessTokenObj = JSON.parse(localStorage.getItem("userActive"));
    //     history.push(`/reset_password?token=${accessTokenObj.token}`)
    //  }

    const showMyCookies = () => {
        // console.log("showing coookies from header", cookies);    
    }

    useEffect(() => {
    //   setUserInfo(cookies.userInformation);
    })

    return (
        <>
        {/* <title>{pageTitle}</title> */}
        {/* <div className={props.dark ? 'header dark' : 'header'}> */}
        <div style={{borderBottom: "1px solid rgb(57 105 213 / 20%)"}} className={'header'}>
            <nav className="navbar navbar-expand-lg navbar-light">
                <Container fluid>
                    <Row className='justify-content-between w-100 m-0'>
                        <Col md={2} xs={6} className=''>
                            <div className="navbar-brand">
                                <img src={logo} alt="logo" className='imglogo' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </nav>
        </div>
        </>
    )
}

export default Navigation;
