

export const TickerValueChange = (value: number | string): string => {
    if(value <= 0) {
        return "#E12727"
    } else {
        return "#3fb61a"
    }
}

export const ValueIsNegative = (value: number): boolean => {
    if(value <= 0) {
        return true;
    } else {
        return false;
    }
} 