import React , {useEffect, useState}from 'react';
import Slider from "react-slick";
import Watchlist from './WatchList';
import AddWatchList from './AddWatchList';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Watchlist.css';

interface IData {
    title: string;
}

const WatchListTabs = () => {

    let [createModal, setCreateModal] = useState<boolean>(false);

    let [activeValue , setActiveValue] = useState<number>(0);

    const [watchListItems , setWatchListItems] = useState<IData[]>([
        {
            title: "View All",
        },
        {
            title: "WatchList 1",
        },
        {
            title: "WatchList 2",
        },
        {
            title: "WatchList 3",
        },
        {
            title: "WatchList 4",
        },
        {
            title: "WatchList 5",
        },
        {
            title: "WatchList 6",
        },
        {
            title: "WatchList 7",
        },
        {
            title: "WatchList 8",
        },
        {
            title: "WatchList 9",
        },
        {
            title: "WatchList 10",
        },
        {
            title: "WatchList 11",
        },
        {
            title: "WatchList 12",
        },
        {
            title: "WatchList 13",
        }
    ])

    const settings = {
        infinite: false,
        slidesToShow: 7,
        slidesToScroll: 1,
        speed: 250,
        cssEase: "linear",
    };

    function closeModal() {
        setCreateModal(false);
    }

    const showCreate = () => {
        setCreateModal(true)
    }

    const handleSubmit = (val: string) => {
        console.log("handle submit val" , val);
        let watchlistItemsClone = [...watchListItems , 
            {
                title : val,
            }
        ];
        setWatchListItems(watchlistItemsClone);
        closeModal();
    }


    const tabClick = (e:any) => {
        let val = parseInt(e.target.getAttribute('data-id'));
        console.log(val);
        setActiveValue(val);
    }

    let listingReturn:any = watchListItems.map( (d , i) => {
        return(
            <span className={(i === activeValue) ? "nav-link active" : "nav-link"} data-id={i} onClick={(e) => tabClick(e)}>{d.title}</span>
        )
    })

    useEffect(() => {
        listingReturn = watchListItems.map( (d , i) => {
            return(
                <span className={(i === activeValue) ? "nav-link active" : "nav-link"} data-id={i} onClick={(e) => tabClick(e)}>{d.title}</span>
            )
        })
    }, [activeValue])

    return (
        <div className='watchlist-tabs-view'>
            <div className='slider-tab-block'>
                {/* <span className={(0 === activeValue) ? "first-nav-link active" : "first-nav-link"} data-id={0} onClick={(e) => tabClick(e)}>View All</span> */}
                <Slider {...settings}>
                    {listingReturn}
                </Slider>
                <button className='btn btn-light' onClick={showCreate}>+ Create New WatchList</button>
            </div>
            <Watchlist/>
            <AddWatchList closeModal={closeModal} createModal={createModal} handleSubmit={handleSubmit}/>
        </div>
    )
}

export default WatchListTabs;
