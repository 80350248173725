import React, { useState } from 'react';
import { useEffect } from 'react';
import searchIcon from '../../../../assets/media/icons/search-solid.svg';
import cancelIcon from '../../../../assets/media/icons/cross.svg'
import './Search.css';
import { Console } from 'console';

interface ISuggestion {
    id: number|string;
    name: string;
    symbol: string;
    sector: string;
}

type AppProps = {
    suggestions: ISuggestion;
    handleQuerySelect: Function
}

type defaultType = {
    id: string;
    item: ISuggestion
}

export default function MySearchBar( { suggestions , handleQuerySelect } : AppProps ) {

    const [showSuggestions, setShowSuggestions] = useState(false);
    const [query, setQuery] = useState("")
    const [searchBarValue, setSearchBarValue] = useState<any>({mainSearchBar: ''});
    const [activeList, setActiveList] = useState(false);
    const [suggestionData, setSuggestionData] = useState<any>();


//   const handleItemSelect = ({id , item} : defaultType) => {
//     handleQuerySelect(id, item);
//     setActiveList(false);
//   }
  const handleItemSelect = (item : ISuggestion) => {
    handleQuerySelect(item.id, item);
    setActiveList(false);
  }
  
  const setSearchQuery = (value:any) => {
    setQuery(value);
  } 

  const handleShowSuggestions = (value: any) => {
    if(value.length > 0 ) {
        setShowSuggestions(false);
        setActiveList(true);
    } else {
        setShowSuggestions(true);
        setActiveList(false);
    }
  }

  const handleSearchBarValue = (event:any) => {
    setSearchBarValue({
      [event.target.name]: event.target.value
    });
  }

    let suggestionList : any = [];
    let filterDataList : any;


    useEffect( () => {
        console.log(suggestions , "=====> suggestions---");
        setSuggestionData(suggestions);
    }, []); 

    useEffect(() => {
        console.log(suggestionData , "=====> suggestionData");
        setSuggestionData(suggestions);
        if(suggestionData){            
            suggestionList = suggestionData.filter((item:any) => {
                if (query === '') {
                    return item;
                } else if (item.name.toLowerCase().includes(query.toLowerCase())) {
                    return item;
                } else if (item.symbol.toLowerCase().includes(query.toLowerCase())) {
                    return item;
                }
            }).slice(0 , 20);
            console.log(suggestionList , "suggestionListsuggestionListsuggestionList");
            filterDataList = suggestionList.map( (item:any , index: number) : any => {
                return(
                    <div key={index}
                            data-company={`${item.name}`}
                            data-sector={`${item.sector}`}
                            data-symbol={`${item.symbol}`}
                            id={item.id.toString()} 
                            onClick={ (e) => {  
                                        handleItemSelect(item); 
                                        setSearchBarValue({mainSearchBar: e.currentTarget.getAttribute('data-company')}); 
                                    }} 
                            className="searchbar-dropdown-item d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-between">
                            <p className='mr-3'><strong>{item.symbol}</strong></p>
                            <p className='company-name' style={{color: "gray"}}>{item.name}</p>
                        </div>
                        <div>
                            <p className="sector" style={{color: "gray"}}>{item.sector}</p>
                        </div>
                    </div>
                );
            });
            // console.log(filterDataList , "=====> suggestionDataList");
        }
    }, [suggestionList])


  // const blurEvent = (e) =>{
  //   setActiveList(false);
  // }


                    /*Key Board events implementation start*/ 

//   var divs = document.getElementsByClassName('searchbar-dropdown-item'), selectedDiv = 0 , eleId = 189;
//   var gray = '#ececec';
//   const keyDownHandler = (e) =>{
//     let x = 0;
//     if(e.keyCode == 38){
//       x = -1;
//     }
//     else if(e.keyCode == 40){
//       x = 1;      
//     }
//     else{
//       return;
//     }
//     divs[selectedDiv].style.backgroundColor = '';
//     selectedDiv = (selectedDiv + x) % divs.length;
//     selectedDiv = selectedDiv < 0 ? (divs.length + selectedDiv) : selectedDiv;
//     document.getElementById('search-input').value = divs[selectedDiv].getAttribute('data-company');
//     divs[selectedDiv].style.backgroundColor = gray;
//   };

//   const keyEnterHandler = (e) => {
//     if(document.getElementById('search-input')?.value != ''){
//       if(e.key == "Enter"){
//         let item = {
//           id: divs[selectedDiv].getAttribute('id'), 
//           name: divs[selectedDiv].getAttribute('data-company'),
//           sector: divs[selectedDiv].getAttribute('data-sector'),
//           symbol: divs[selectedDiv].getAttribute('data-symbol')
//         }
//         setSearchBarValue({mainSearchBar: divs[selectedDiv].getAttribute('data-company')});
//         handleItemSelect(item.id , item); 
//         setActiveList(false);
//       }
//     }
//   }

                    /*Key Board events implementation end*/ 

//  MAIN RENDER FUNCTION

  return(
  <>
    <div className='search-main' onMouseLeave={handleShowSuggestions}>
        <p className='search-field'>
            <input 
                id="search-input" name='mainSearchBar' value={ searchBarValue.mainSearchBar } placeholder="Search by Company Name" 
                onChange={ (e) => {setSearchQuery(e.target.value); handleShowSuggestions(e.target.value);handleSearchBarValue(e);}} 
                // onKeyPress={(e) => keyEnterHandler(e)}
                // onKeyDown={(e) => keyDownHandler(e)}
                autoComplete="off"
                type="text" className="form-control"
            />
            {/* <input id="search-input" name='mainSearchBar' value={ searchBarValue.mainSearchBar } placeholder="Search by Company Name" 
                  onChange={ (e) => { 
                            setSearchQuery(e.target.value); 
                            handleShowSuggestions(e.target.value);
                            hanleSearchBarValue(e);
                            }} 
                  onKeyPress={(e) => keyEnterHandler(e)}
                  onKeyDown={(e) => keyDownHandler(e)}
                  autoComplete="off"
                  type="text" className="form-control" /> */}
            {activeList === false ? 
              <span className='icon-search' >
                  <img src={searchIcon} alt="search"/>
              </span>
            :
              <span className='icon-search' onClick={()=> setSearchBarValue({mainSearchBar:''})} > 
                 <img src={cancelIcon} alt="search" />
              </span>
            }
        </p>
        <div className={activeList === true ? "searchbar-dropdown-wrapper active" : "searchbar-dropdown-wrapper"}>
            {/* { (suggestionList.length === 0) ? <p>No data Found</p> : suggestionList   } */}
            {/* { suggestionList.map( (item:any , index: number) => (
                <div key={index}
                        data-company={`${item.name}`}
                        data-sector={`${item.sector}`}
                        data-symbol={`${item.symbol}`}
                        id={item.id.toString()} 
                        onClick={ (e) => {  
                                    handleItemSelect(item); 
                                    setSearchBarValue({mainSearchBar: e.currentTarget.getAttribute('data-company')}); 
                                }} 
                        className="searchbar-dropdown-item d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between">
                        <p className='mr-3'><strong>{item.symbol}</strong></p>
                        <p className='company-name' style={{color: "gray"}}>{item.name}</p>
                    </div>
                    <div>
                        <p className="sector" style={{color: "gray"}}>{item.sector}</p>
                    </div>
                </div>
            ))} */}
            {filterDataList}
        </div> 
    </div>
  </>
  );
}
