import React , {useState , useEffect} from 'react';
import axios from 'axios';
// import LoadingImage from '../../assets/media/loader.gif';
import { HandleOnSearchData } from '../../modules/WatchList/modals/HandleOnSearchData';
import IndicesTicker from './sections/IndicesTicker/IndicesTicker';
import Navigation from '../../components/Navigation/Navigation';
import './index.css';
// import WatchList from './sections/WatchList/WatchList';
import WatchListTabs from './sections/WatchList/WatchListTabs';
import SearchBar from './sections/SearchBar/Search'

interface ISuggestion {
    id: number|string;
    name: string;
    symbol: string;
    sector: string;
}

type defaultType = {
    id: string;
    item: ISuggestion
}

export default function Index() {
    const [searchItems , setSearchItems] = useState<any>([{
        id: '',
        name: '',
        symbol: '',
        sector: ''
    }]);
    // const [searchSelected , setSearchSelected] = useState<ISuggestion>();
    const [searchSelected , setSearchSelected] = useState<any>();
    const [fetchingDataLoader, setFetchingDataLoader] = useState(false);
    const [loadingFlag , setLoadingFlag] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState({});



    const handleOnSearch = () => {
        // axios.post('https://askanalyst-testing.akseerresearch.com/api/search/suggestions',{
        //   params: {
        //     _limit: 5
        //   }
        // },
        //  { headers: { 'Authorization': `Bearer ${accessToken}` } })
        // .then(function (response) {
        //   setSearchItems(response.data);
        // })
        // .catch(function (error) {
            //   console.log(error);
        // });
            
        setSearchItems(HandleOnSearchData);
    }

    const __handleOnSelect = (item:ISuggestion) => {
        setFetchingDataLoader(true);
        // const searchCompany = 
  
        // axios.post('https://askanalyst-testing.akseerresearch.com/api/searched/company',{
        //   search: item.id,
        // },
        // {
        //   headers: {
        //     "Access-Control-Allow-Origin": "*",
        //     "Authorization": `Bearer ${cookies?.accessToken}`
        //   }
        // }).then((response) => {
        //   const searchCompanyData = response.data.api;
        //   console.log(searchCompanyData , "defaultComp axios simultaneaous ====>");
        //   setSearchSelected(item);
        //   setSearchItems(response.data);
        //   setFetchingDataLoader(false);
        //   setSelectedCompany(searchCompanyData.company);
        //   setLoadingFlag(true);
        //   setIndustryData(industryData);
        // }).catch((error) =>{
        //   console.log(error , "error in simultaneaous request...");
        //   setFetchingDataLoader(false);
        //   setAlert(true);
        // })


        // const searchCompany = HandleOnSearchData.filter((i) => i.id == item.id);
        // console.log(searchCompany , "defaultComp axios simultaneaous ====>");
        // setSearchSelected(searchCompany);
        // setFetchingDataLoader(false);
        // setSelectedCompany(searchCompany);
        // setLoadingFlag(true);

    }

    const handleQuerySelect = ({item}:defaultType) => {
        __handleOnSelect(item);
    }


    useEffect(() => {
        handleOnSearch();
    },[])


    return (
        <>
            <Navigation />
            <div className='custom-container'>
                <IndicesTicker />
                <div className='col-md-12 my-4'>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="col-md-4">
                            <h3 className='m-0 watchlist-title'>Watchlist</h3>
                        </div>
                        <div className="col-md-8 d-flex flex-wrap justify-content-end">
                            {/* <SearchBar suggestions={(searchItems) ? searchItems : [{}]} handleQuerySelect={handleQuerySelect} /> */}
                            <SearchBar suggestions={searchItems} handleQuerySelect={handleQuerySelect} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {/* <WatchList /> */}
                        <WatchListTabs />
                    </div>
                </div>
            </div>
        </>
    )
}
