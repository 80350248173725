import React from 'react';
import logo from './logo.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import { Route, Routes } from 'react-router-dom';
import WatchList from './modules/WatchList';

function App() {
  return (
    <Routes>
      <Route path="/"  element={<WatchList />} />
    </Routes>
  );
}

export default App;
