import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Marquee from 'react-fast-marquee'
import { IndicesTickerData } from '../../modals/IndicesStickerData';
import { TickerValueChange, ValueIsNegative } from '../../../../GlobalFunctions';
const ArrowDrop = require('../../../../assets/media/arrow-drop.svg').default;
const ArrowRaise = require('../../../../assets/media/arrow-raise.svg').default;

interface IIndicesTickerData {
  id: number;
  title: string;
  total: number;
  up_value: number;
  down_value: number;
}

export default function IndicesTicker() {
  
  const [data, setData] = useState<IIndicesTickerData[]>();

  const settings = {
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    speed: 1000,
    cssEase: "linear",
  };


  useEffect(() => {
    console.log("Indices Data =====>",IndicesTickerData)
    setData(IndicesTickerData);
  })
  
  return (
    <div className='marquee-slider'>
      <Slider {...settings}>
          {
            data?.map( (d) => {
              return(
              <div className='indice-parent'>
                <div className='d-inline-flex flex-column'>
                  <p className='indice-title m-0 d-inline-flex'>{d?.title}</p>
                  <p className="indice-total-number indice-small-font m-0 d-inline-flex">{d.total}</p>
                </div>
                <div className='d-inline-flex flex-column align-items-end'>
                  <p style={{color: TickerValueChange(d.up_value)}} className='up-value indice-small-font m-0'><span><img src={ValueIsNegative(d.up_value) ? ArrowDrop : ArrowRaise}/></span> {d.up_value}</p>
                  <p style={{color: TickerValueChange(d.down_value)}} className="down-value indice-small-font m-0">{(ValueIsNegative(d.down_value)) ? `(${d.down_value})` : `${d.down_value}` }</p>
                </div>
              </div>
              )
            })
          }
      </Slider>
    </div>
  )
}
