import { useEffect, useState } from 'react'
import { WatchListData } from '../../modals/WatchListData';
import {TickerValueChange , ValueIsNegative} from '../../../../GlobalFunctions';
import './Watchlist.css'

const ArrowDrop = require('../../../../assets/media/arrow-drop.svg').default;
const ArrowRaise = require('../../../../assets/media/arrow-raise.svg').default;

export default function WatchList() {
  
  interface IWatchListData {
    id: number;
    symbol: string;
    name: string;
    current_price: number;
    change_in_price: number;
    percentage_price_in_change: number;
    volumne: number;
    high: number;
    low: number;
    ldcp: number;
  }

  const [data, setData] = useState<IWatchListData[]>();
 
  useEffect( () => {
    // console.log("Watch List Data", WatchListData);
    setData(WatchListData);
  })
  
  if(data) {
    return (
      <div className="watchlist-table-card">
        <div className="watchlist-table-card-header">
          
        </div>
        <div className="watchlist-table-card-body">
            <table className='table'>
                <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Name</th>
                    <th>Current Price</th>
                    <th>Change In Price</th>
                    <th>%Price Change</th>
                    <th>Volume</th>
                    <th>High</th>
                    <th>Low</th>
                    <th>LDCP</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map(((d) => {
                      return(
                        <tr>
                          <td><span>{d.symbol}</span></td>
                          <td><span>{d.name}</span></td>
                          <td>{d.current_price}</td>
                          <td style={{color: TickerValueChange(d.change_in_price)}}><p><span><img src={ValueIsNegative(d.change_in_price) ? ArrowDrop : ArrowRaise}/></span>{d.change_in_price}</p></td>
                          <td style={{color: TickerValueChange(d.change_in_price)}}><p><span><img src={ValueIsNegative(d.percentage_price_in_change) ? ArrowDrop : ArrowRaise}/></span>{d.percentage_price_in_change}</p></td>
                          <td>{d.volumne}</td>
                          <td>{d.high}</td>
                          <td>{d.low}</td>
                          <td>{d.ldcp}</td>
                          <td className='remove-action'><span>x</span></td>
                        </tr>
                      )
                    }))
                  }
                </tbody>
            </table>
        </div>
      </div>
  )
  } else {
    return(<h1>loading . . . .</h1>)
  }
}