export const HandleOnSearchData = [
    {
        "id": 1,
        "name": "Abbot Laboratories (Pakistan) Ltd",
        "symbol": "ABOT",
        "sector": "PHARMACEUTICALS",
    },
    {
        "id": 2,
        "name": "Abdullah Shah Ghazi Sugar Mills Ltd",
        "symbol": "AGSML",
        "sector": "SUGAR & ALLIED INDUSTRIES",
    },
    {
        "id": 3,
        "name": "Adam Sugar Mills Ltd",
        "symbol": "ADAMS",
        "sector": "SUGAR & ALLIED INDUSTRIES",
    },
    {
        "id": 4,
        "name": "Adamjee Insurance Company Ltd",
        "symbol": "AICL",
        "sector": "INSURANCE",
    },
    {
        "id": 5,
        "name": "Ados Pakistan Ltd",
        "symbol": "ADOS",
        "sector": "ENGINEERING",
    },
    {
        "id": 6,
        "name": "Agha Steel Ind.Ltd",
        "symbol": "AGHA",
        "sector": "ENGINEERING",
    },
    {
        "id": 7,
        "name": "AGP Ltd",
        "symbol": "AGP",
        "sector": "PHARMACEUTICALS",
    },
    {
        "id": 8,
        "name": "Agriauto Industries Ltd",
        "symbol": "AGIL",
        "sector": "AUTOMOBILE PARTS & ACCESSORIES",
    },
    {
        "id": 9,
        "name": "Agritech Ltd",
        "symbol": "AGL",
        "sector": "CHEMICAL",
    },
    {
        "id": 10,
        "name": "Ahmad Hassan Textile Mills Ltd",
        "symbol": "AHTM",
        "sector": "TEXTILE COMPOSITE",
    },
    {
        "id": 11,
        "name": "Aisha Steel Mills Ltd",
        "symbol": "ASL",
        "sector": "ENGINEERING",
    },
    {
        "id": 12,
        "name": "Al Shaheer Corporation",
        "symbol": "ASC",
        "sector": "FOOD & PERSONAL CARE PRODUCTS",
    },
    {
        "id": 13,
        "name": "Al-Abbas Sugar Mills Ltd",
        "symbol": "AABS",
        "sector": "SUGAR & ALLIED INDUSTRIES",
    },
    {
        "id": 14,
        "name": "Al-Abid Silk Mills Ltd",
        "symbol": "AASM",
        "sector": "SYNTHETIC & RAYON",
    },
    {
        "id": 15,
        "name": "Al-Ghazi Tractors Ltd",
        "symbol": "AGTL",
        "sector": "AUTOMOBILE ASSEMBLER",
    },
    {
        "id": 16,
        "name": "Al-Khair Gadoon Ltd",
        "symbol": "AKGL",
        "sector": "MISCELLANEOUS",
    },
    {
        "id": 17,
        "name": "Al-Noor Sugar Mills Ltd",
        "symbol": "ALNRS",
        "sector": "SUGAR & ALLIED INDUSTRIES",
    },
    {
        "id": 18,
        "name": "Allawasaya Textile and Finishing Mills Ltd",
        "symbol": "AWTX",
        "sector": "TEXTILE SPINNING",
    },
    {
        "id": 19,
        "name": "Allied Bank Ltd",
        "symbol": "ABL",
        "sector": "COMMERCIAL BANKS",
    },
    {
        "id": 20,
        "name": "Altern Energy Ltd",
        "symbol": "ALTN",
        "sector": "POWER GENERATION & DISTRIBUTION",
    },
    {
        "id": 21,
        "name": "Amreli Steels Ltd",
        "symbol": "ASTL",
        "sector": "ENGINEERING",
    },
    {
        "id": 22,
        "name": "Archroma Pakistan Ltd",
        "symbol": "ARPL",
        "sector": "CHEMICAL",
    },
    {
        "id": 23,
        "name": "Arif Habib Ltd",
        "symbol": "AHL",
        "sector": "INV. BANKS / INV. COS. / SECURITIES COS.",
    },
    {
        "id": 24,
        "name": "Artistic Denim Mills Ltd",
        "symbol": "ADMM",
        "sector": "TEXTILE COMPOSITE",
    },
    {
        "id": 25,
        "name": "Askari Bank Ltd",
        "symbol": "AKBL",
        "sector": "COMMERCIAL BANKS",
    },
    {
        "id": 26,
        "name": "Askari General Insurance Co. Ltd",
        "symbol": "AGIC",
        "sector": "INSURANCE",
    },
    {
        "id": 27,
        "name": "At-Tahur Ltd",
        "symbol": "PREMA",
        "sector": "FOOD & PERSONAL CARE PRODUCTS",
    },
    {
        "id": 28,
        "name": "Atlas Battery Ltd",
        "symbol": "ATBA",
        "sector": "AUTOMOBILE PARTS & ACCESSORIES",
    },
    {
        "id": 29,
        "name": "Atlas Honda Ltd",
        "symbol": "ATLH",
        "sector": "AUTOMOBILE ASSEMBLER",
    },
    {
        "id": 30,
        "name": "Atlas Insurance Ltd",
        "symbol": "ATIL",
        "sector": "INSURANCE",
    },
    {
        "id": 31,
        "name": "Attock Cement Pakistan Ltd",
        "symbol": "ACPL",
        "sector": "CEMENT",
    },
    {
        "id": 32,
        "name": "Attock Petroleum Ltd",
        "symbol": "APL",
        "sector": "OIL & GAS MARKETING COMPANIES",
    },
    {
        "id": 33,
        "name": "Attock Refinery Ltd",
        "symbol": "ATRL",
        "sector": "REFINERY",
    },
    {
        "id": 34,
        "name": "Avanceon Ltd",
        "symbol": "AVN",
        "sector": "TECHNOLOGY & COMMUNICATION",
    },
    {
        "id": 35,
        "name": "Azgard Nine Ltd",
        "symbol": "ANL",
        "sector": "TEXTILE COMPOSITE",
    },
    {
        "id": 36,
        "name": "Baba Farid Sugar Mills Ltd",
        "symbol": "BAFS",
        "sector": "SUGAR & ALLIED INDUSTRIES",
    },
    {
        "id": 37,
        "name": "Babri Cotton Mills Ltd",
        "symbol": "BCML",
        "sector": "TEXTILE SPINNING",
    },
    {
        "id": 38,
        "name": "Balochistan Glass Ltd",
        "symbol": "BGL",
        "sector": "GLASS & CERAMICS",
    },
    {
        "id": 39,
        "name": "Baluchistan Wheels Ltd",
        "symbol": "BWHL",
        "sector": "AUTOMOBILE PARTS & ACCESSORIES",
    },
    {
        "id": 40,
        "name": "Bank Al Habib Ltd",
        "symbol": "BAHL",
        "sector": "COMMERCIAL BANKS",
    },
    {
        "id": 41,
        "name": "Bank Alfalah Ltd",
        "symbol": "BAFL",
        "sector": "COMMERCIAL BANKS",
    },
    {
        "id": 42,
        "name": "Bankislami Pakistan Ltd",
        "symbol": "BIPL",
        "sector": "COMMERCIAL BANKS",
    },
    {
        "id": 43,
        "name": "Bannu Woollen Mills Ltd",
        "symbol": "BNWM",
        "sector": "WOOLLEN",
    },
    {
        "id": 44,
        "name": "Bata Pakistan Ltd",
        "symbol": "BATA",
        "sector": "LEATHER & TANNERIES",
    },
    {
        "id": 45,
        "name": "Bawanyair Products Ltd",
        "symbol": "BAPL",
        "sector": "CHEMICAL",
    },
    {
        "id": 46,
        "name": "Beco Steel Ltd",
        "symbol": "BECO",
        "sector": "ENGINEERING",
    },
    {
        "id": 47,
        "name": "Berger Paints Pakistan Ltd",
        "symbol": "BERG",
        "sector": "CHEMICAL",
    },
    {
        "id": 48,
        "name": "Bestway Cement Ltd",
        "symbol": "BWCL",
        "sector": "CEMENT",
    },
    {
        "id": 49,
        "name": "Bhanero Textile Mills Ltd",
        "symbol": "BHAT",
        "sector": "TEXTILE COMPOSITE",
    },
    {
        "id": 50,
        "name": "Biafo Industries Ltd",
        "symbol": "BIFO",
        "sector": "CHEMICAL",
    },
    {
        "id": 51,
        "name": "Bilal Fibres Ltd",
        "symbol": "BILF",
        "sector": "TEXTILE SPINNING",
    },
    {
        "id": 52,
        "name": "Blessed Textiles Ltd",
        "symbol": "BTL",
        "sector": "TEXTILE COMPOSITE",
    },
    {
        "id": 53,
        "name": "Bolan Castings Ltd",
        "symbol": "BCL",
        "sector": "ENGINEERING",
    },
    {
        "id": 54,
        "name": "Bunny's Ltd",
        "symbol": "BNL",
        "sector": "FOOD & PERSONAL CARE PRODUCTS",
    },
    {
        "id": 55,
        "name": "Burshane LPG (Pakistan) Ltd",
        "symbol": "BPL",
        "sector": "OIL & GAS MARKETING COMPANIES",
    }
]