export const WatchListData = [
    {
        id: 1,
        symbol: "ISL",
        name: "International Steels",
        current_price: 20099,
        change_in_price: 54.3,
        percentage_price_in_change: 5.95,
        volumne: 754459,
        high: 43.39,
        low: 42.76,
        ldcp: 74.78 
    },
    {
        id: 1,
        symbol: "HMBL",
        name: "Habib  Metropoliton",
        current_price: 1545,
        change_in_price: 80.5,
        percentage_price_in_change: 8.68, 
        volumne: 836175,
        high: 1781,
        low: 1153,
        ldcp: 1487 
    },
    {
        id: 1,
        symbol: "INIL",
        name: "International Industries",
        current_price: 29.64, 
        change_in_price: -3.8, 
        percentage_price_in_change: -7.68, 
        volumne: 794453,
        high: 43.39,
        low: 42.76,
        ldcp: 23.48, 
    },
    {
        id: 1,
        symbol: "TGL",
        name: "Tariq Glass Industries",
        current_price: 2243.58, 
        change_in_price: 124.9, 
        percentage_price_in_change: 12.84, 
        volumne: 894145,
        high: 2914,
        low: 1896,
        ldcp: 2152, 
    },
    {
        id: 1,
        symbol: "FRSM",
        name: "Faran Suger Mills",
        current_price: 200.99, 
        change_in_price: -21.7, 
        percentage_price_in_change: -9.35, 
        volumne: 745168,
        high: 43.39,
        low: 42.76,
        ldcp: 46.81, 
    },
    {
        id: 1,
        symbol: "SHEL",
        name: "Shell Pakistan",
        current_price: 1125.89, 
        change_in_price:  76.7,
        percentage_price_in_change: 16.96, 
        volumne: 514489,
        high: 1541,
        low: 873.68,
        ldcp: 1174, 
    },
    {
        id: 1,
        symbol: "ISL",
        name: "International Steels",
        current_price: 78.18,
        change_in_price: -6.4,
        percentage_price_in_change: -2.57,
        volumne: 144459,
        high: 43.39,
        low: 42.76,
        ldcp: 28.48,
    },

]

