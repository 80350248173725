export const IndicesTickerData = [
    {
        id: 1,
        title: "KSE100",
        total: 4524941,
        up_value: -283.89,
        down_value: -0.62
    },
    {
        id: 2,
        title: "ALLSHR",
        total: 45222341,
        up_value: 1283.89,
        down_value: 0.53
    },
    {
        id: 3,
        title: "KSE30",
        total: 4121341,
        up_value: -283.89,
        down_value: 0.3
    },
    {
        id: 4,
        title: "KMI30",
        total: 41111,
        up_value: -483.89,
        down_value: 0.59
    },
    {
        id: 5,
        title: "BKTI",
        total: 41111,
        up_value: -483.89,
        down_value: 0.59
    },
    {
        id: 4,
        title: "KMI30",
        total: 41111,
        up_value: -483.89,
        down_value: 0.59
    },
    {
        id: 5,
        title: "BKTI",
        total: 41111,
        up_value: -483.89,
        down_value: 0.59
    },
    {
        id: 4,
        title: "KMI30",
        total: 41111,
        up_value: -483.89,
        down_value: 0.59
    },
    {
        id: 5,
        title: "BKTI",
        total: 41111,
        up_value: -483.89,
        down_value: 0.59
    },
    {
        id: 4,
        title: "KMI30",
        total: 41111,
        up_value: -483.89,
        down_value: 0.59
    },
    {
        id: 5,
        title: "BKTI",
        total: 41111,
        up_value: -483.89,
        down_value: 0.59
    },
    {
        id: 4,
        title: "KMI30",
        total: 41111,
        up_value: -483.89,
        down_value: 0.59
    },
    {
        id: 5,
        title: "BKTI",
        total: 41111,
        up_value: -483.89,
        down_value: 0.59
    },
]